import * as z from 'zod'

export const environmentSchema = z.object({
    production: z.boolean(),
    picksEnabled: z.boolean(),
    apiGatewayUrl: z.string(),
    clientId: z.string(),
    baseUrl: z.string(),
    idp: z.string(),
    recaptureSiteKey: z.string(),
    gptNetworkCode: z.string(),
    gptParentCode: z.string(),
    tabtouchWebsiteUrl: z.string(),
    audience: z.string(),
    assetBaseUrl: z.string(),
})

export type EnvironmentSchema = z.infer<typeof environmentSchema>
