import * as z from 'zod'

export const environmentSchema = z.object({
    production: z.boolean(),
    picksEnabled: z.boolean(),
    apiGatewayUrl: z.string(),
    clientId: z.string(),
    baseUrl: z.string(),
    idp: z.string(),
    recaptureSiteKey: z.string(),
    gptNetworkCode: z.string(),
    gptParentCode: z.string(),
    tabtouchWebsiteUrl: z.string(),
    audience: z.string(),
    assetBaseUrl: z.string(),
    useAuth0: z.boolean(),
    publicBucketName: z.string().optional(),
})

export type EnvironmentSchema = z.infer<typeof environmentSchema>

export function validateEnvironment(env: Record<string, string | undefined>) {
    const apiGatewayUrl = env.NX_API_GATEWAY_URL
    const baseUrl = env.NX_SITE_URL
    const useAuth0 = env.NX_USE_AUTH0 === 'true'
    const production = env.NX_ENVIRONMENT === 'prod'

    return environmentSchema.parse({
        production,
        picksEnabled: env.NX_PICKS_ENABLED === 'true',
        baseUrl,
        apiGatewayUrl,
        clientId: useAuth0 ? env.NX_AUTH0_CLIENT_ID : env.NX_AUTH_CLIENT_ID,
        idp: useAuth0
            ? `https://${env.NX_AUTH0_SERVER_BASE}`
            : env.NX_AUTH_ENDPOINT,
        recaptureSiteKey: env.NX_RECAPTCHA_SITE_KEY,
        gptNetworkCode: env.NX_GPT_NETWORK_CODE,
        gptParentCode: env.NX_GPT_PARENT_CODE,
        tabtouchWebsiteUrl: env.NX_TABTOUCH_WEBSITE_URL,
        audience: env.NX_AUTH0_AUDIENCE,
        assetBaseUrl: apiGatewayUrl?.includes('localhost')
            ? apiGatewayUrl
            : baseUrl,
        useAuth0,
        publicBucketName: env.NX_PUBLIC_BUCKET_NAME,
    })
}
