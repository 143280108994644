import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

interface FootballProps extends SvgIconProps {
    gradientFill?: boolean
}

export function AflWFootball({ title = 'AFLW Icon', ...props }: FootballProps) {
    return (
        <SvgIcon
            {...{ title, ...props }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M8.20666 12.6152C8.49955 12.3223 8.97443 12.3223 9.26732 12.6152C9.56021 12.9081 9.56021 13.3829 9.26732 13.6758L7.3589 15.5843C7.06601 15.8772 6.59113 15.8772 6.29824 15.5843C6.00535 15.2914 6.00535 14.8165 6.29824 14.5236L8.20666 12.6152Z" />
            <path d="M7.83605 11.184C7.54315 10.8911 7.06828 10.8911 6.77539 11.184L4.86697 13.0924C4.57407 13.3853 4.57407 13.8602 4.86697 14.1531C5.15986 14.446 5.63473 14.446 5.92763 14.1531L7.83605 12.2447C8.12894 11.9518 8.12894 11.4769 7.83605 11.184Z" />
            <path d="M5.34411 9.75287C5.63701 9.45998 6.11188 9.45998 6.40477 9.75287C6.69767 10.0458 6.69767 10.5206 6.40477 10.8135L4.49635 12.722C4.20346 13.0148 3.72858 13.0148 3.43569 12.722C3.1428 12.4291 3.1428 11.9542 3.43569 11.6613L5.34411 9.75287Z" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.46813 5L10.2794 8.96577C11.1576 9.88378 11.8589 11.0978 12.2767 12.3641L13.9387 5H16.4189L18.7032 15.1696H18.8134L20.8895 5H23.7861L20.1974 19.6054H17.6131L15.2247 10.0563H15.1267L12.7445 19.6054H10.1601L9.97141 18.8373C7.65188 19.4807 4.41451 18.3657 2.53463 16.4858C0.149104 14.1003 -1.00467 9.52883 1.10332 7.42085C2.46786 6.0563 4.86468 6.05852 7.03263 6.87687L6.57146 5H9.46813ZM7.45059 17.4128C6.01997 17.1416 4.55968 16.3896 3.59529 15.4252C2.63091 14.4608 1.87886 13.0005 1.60766 11.5699C1.32915 10.1008 1.6099 9.03558 2.16398 8.48151C2.71805 7.92743 3.78323 7.64669 5.25236 7.92519C6.68298 8.1964 8.14327 8.94844 9.10766 9.91282C10.072 10.8772 10.8241 12.3375 11.0953 13.7681C11.3738 15.2372 11.093 16.3024 10.539 16.8565C9.98489 17.4106 8.91972 17.6913 7.45059 17.4128Z"
            />
        </SvgIcon>
    )
}
export default AflWFootball
