// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { environmentSchema, EnvironmentSchema } from './validate-environment'

export function validateEnvironment(env: Record<string, string | undefined>) {
    const apiGatewayUrl =
        process.env.NX_API_GATEWAY_URL || 'https://api.thegame.swmdigital.io'
    const baseUrl = process.env.NX_SITE_URL || 'http://localhost:4200'

    return environmentSchema.parse({
        production: false,
        picksEnabled: env.NX_PICKS_ENABLED === 'true',
        baseUrl,
        apiGatewayUrl,
        clientId:
            env.NX_USE_AUTH0 === 'true'
                ? env.NX_AUTH0_CLIENT_ID || 'oweNxwHY5AYOLaNKTUMBI7aV8o2OZKpT'
                : env.NX_AUTH_CLIENT_ID || 'eeq5cwzhen0y1na59vpi7h69xfkgtbb0',
        idp:
            env.NX_USE_AUTH0 === 'true'
                ? env.NX_AUTH0_SERVER_BASE
                    ? `https://${env.NX_AUTH0_SERVER_BASE}`
                    : 'https://dev-wanews.au.auth0.com'
                : env.NX_AUTH_ENDPOINT ||
                  'https://id-dev.swmdigital.io/connect',
        recaptureSiteKey:
            env.NX_RECAPTCHA_SITE_KEY ||
            '6LdAINoZAAAAAKN1lqff5DtXQQlYCcDTtca4GAqP',
        gptNetworkCode: env.NX_GPT_NETWORK_CODE || '60035833',
        gptParentCode: env.NX_GPT_PARENT_CODE || 'wan/thegame/web',
        tabtouchWebsiteUrl:
            env.NX_TABTOUCH_WEBSITE_URL || 'https://wwwqa.tabtouch.com.au',
        audience: env.NX_AUTH0_AUDIENCE || 'id-dev.swmdigital.io',
        assetBaseUrl: apiGatewayUrl.includes('localhost')
            ? apiGatewayUrl
            : baseUrl,
    })
}

export const environment: EnvironmentSchema = validateEnvironment(process.env)
